import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyCDY8c023opmUy9D81HF1HCHR8y2AT7mg8",
    authDomain: "mhchatbot-b18ca.firebaseapp.com",
    projectId: "mhchatbot-b18ca",
    storageBucket: "mhchatbot-b18ca.appspot.com",
    messagingSenderId: "963805274379",
    appId: "1:963805274379:web:2131f1015177a7f836c20e",
    measurementId: "G-CCJF15H1SX"
  };

const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
//   connectFirestoreEmulator(db, "127.0.0.1", 8080);
//   connectAuthEmulator(auth, "http://127.0.0.1:9099");
// }

export { db, auth, googleProvider };