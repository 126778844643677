import React from 'react';
import { useTheme } from '@mui/material/styles';

function Logo(props) {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 254.528 254.097" {...props}>
            <g>
                <g id="Layer_1">
                    <g id="Generative_Object">
                        <g>
                            <path d="M127.218,254.097c-18.436,0-36.783-3.979-53.362-11.605-19.296-8.876-35.625-22.166-48.533-39.498C-2.724,165.332-7.803,117.268,11.736,74.421,31.281,31.562,70.914,3.875,117.753.357h0C120.761.132,123.794.012,126.767,0c.161,0,.324,0,.484,0,46.773,0,88.247,24.464,111.017,65.516,22.843,41.183,21.552,89.499-3.453,129.245-11.508,18.292-26.742,32.821-45.282,43.184-17.374,9.712-37.188,15.264-57.297,16.054-1.671.065-3.346.099-5.018.099ZM118.282,7.393h0c-44.235,3.322-81.665,29.474-100.126,69.956C-.3,117.819,4.494,163.213,30.981,198.778c23.974,32.192,60.762,49.745,100.978,48.17,40.198-1.58,75.511-21.971,96.885-55.944,23.613-37.535,24.83-83.167,3.254-122.065C210.517,30.03,171.144,6.933,126.793,7.056c-2.806.011-5.67.124-8.511.337Z" fill={theme.palette.text.primary}/>
                            <g>
                                <path d="M195.112,83.745c-11.2.201-26.031,4.38-33.562,12.85-2.711,3.049-1.605,6.958-7.357,5.795,1.571,5.671,2.641,12.262,2.62,18.142-.098,27.698-15.602,40.57-22.741,54.584-1.21,2.376-2.638,3.873-1.919,4.738-.939.34-1.884.659-2.825.983-.507.174-1.015.34-1.522.507l-.627.201c-.176.056-.352.109-.527.162l-.27.09c-1.6.118-3.23.671-4.843.85l-.236-4.775c1.027.229,2.357.787,3.046.803l.351-.534c-2.204-4.562-42.884-41.131-20.59-86.126,2.709-5.466,17.474-24.602,23.172-24.497,4.709.086,19.083,15.919,23.223,24.378.871-.545,1.742-1.05,2.624-1.581,9.217-5.55,19.186-10.872,30.09-12.113,8.048-.916,8.531-1.653,11.893,5.543Z" fill="#fefefe"/>
                                <path d="M195.112,83.745c6.112,13.082,3.874,34.681-2.105,47.477-1.473,3.153-3.459,6.051-5.353,8.964-.107.164-.194.341-.302.504-15.049,22.716-30.513,30.221-55.198,39.165-.719-.865.709-2.363,1.919-4.738,7.138-14.014,22.642-26.886,22.741-54.584.021-5.88-1.049-12.471-2.62-18.142,5.752,1.163,4.646-2.746,7.357-5.795,7.531-8.471,22.362-12.65,33.562-12.85Z" fill="#fecb3f"/>
                                <path d="M104.11,92.016c-22.295,44.994,18.385,81.563,20.59,86.126l-.351.534c-.689-.017-2.019-.575-3.046-.803-23.758-5.288-41.121-17.141-54.594-37.686-.107-.164-.196-.339-.302-.504-1.462-2.271-3.389-5.239-4.528-7.593-.632-1.306-.916-2.617-1.437-3.938.958-.317,4.395.334,4.856-.312,1.2-1.683-6.183-19.6,1.512-40.819,1.074-2.963,4.015-3.531,3.024-9.02,10.991.912,22.324,6.67,31.603,12.312.909.553,1.864,1.017,2.673,1.703Z" fill="#fecb3f"/>
                                <path d="M129.329,180.838c.941-.324,1.886-.643,2.825-.983,24.685-8.944,40.15-16.449,55.198-39.165.108-.163.196-.34.302-.504,2.064.407,15.221,1.276,15.975,1.008,1.443-.513.191-3.837,1.26-4.838-.164-.075-.339-.127-.504-.202.165.074.34.126.504.202,16.363,7.489,18.416,8.696,10.482,21.972-7.171,12-17.451,20.375-30.639,24.995-23.79,8.335-42.657-1.368-55.146-1.764l-.256-.721Z" fill="#f06241"/>
                                <path d="M66.709,140.186c13.473,20.545,30.836,32.397,54.594,37.686l.236,4.775c-12.037,1.334-23.81,4.878-36.083,3.901-20.644-1.643-41.688-14.257-49.285-34.268,5.83-.656,9.887-5.52,15.269-8.063,4.49-2.121,10.087-3.728,14.967-4.535.106.165.195.34.302.504Z" fill="#f06241"/>
                                <path d="M69.834,78.001c.992,5.489-1.949,6.057-3.024,9.02-7.694,21.219-.311,39.136-1.512,40.819-.461.646-3.898-.005-4.856.312-5.011-12.72-6.723-30.559-1.494-43.398,2.996-7.356,3.235-7.388,10.885-6.753Z" fill="#fefefe"/>
                                <path d="M66.709,140.186c-.107-.164-.196-.339-.302-.504-4.88.808-10.477,2.414-14.967,4.535-5.382,2.543-9.44,7.407-15.269,8.063-2.778-7.318-2.677-8.349,11.49-15.017,3.713-1.748,7.618-2.879,11.518-4.09.942-.292,1.857-.578,2.7-1.084,1.139,2.354,3.066,5.321,4.528,7.593.106.165.195.34.302.504Z" fill="#fefefe"/>
                                <path d="M204.385,136.155c.165.074.34.126.504.202-1.069,1.001.184,4.325-1.26,4.838-.754.268-13.911-.6-15.975-1.008-.107.164-.194.341-.302.504.108-.163.196-.34.302-.504,1.894-2.913,3.88-5.811,5.353-8.964.618.846,1.565.911,2.422,1.33,2.915,1.424,6.029,2.285,8.956,3.603Z" fill="#fefefe"/>
                            </g>
                        </g>
                        <g>
                            <path d="M89.785,190.496c-1.533,0-3.075-.056-4.629-.18-23.8-1.895-44.906-16.641-52.519-36.693-1.407-3.707-2.861-7.539-.14-11.307,1.971-2.729,5.61-4.732,13.553-8.473,3.563-1.677,7.231-2.807,10.78-3.9l1.229-.379c1.995-.621,4.111.496,4.73,2.489.619,1.994-.496,4.111-2.49,4.729l-1.243.385c-3.449,1.062-6.707,2.065-9.786,3.516-4.596,2.163-9.587,4.594-10.645,6.059-.282.391.182,1.835,1.079,4.198,6.597,17.377,25.104,30.173,46.051,31.841,7.767.617,15.326-.686,23.331-2.065,3.917-.676,7.966-1.374,12.036-1.825.554-.061,1.19-.199,1.864-.346.935-.203,1.994-.434,3.117-.517.283-.021.566-.06.85-.097.786-.104,1.758-.229,2.848-.147,3.981.14,8.446,1.111,13.172,2.139,11.004,2.394,24.701,5.374,40.509-.167,12.321-4.316,21.958-12.178,28.645-23.366,1.327-2.223,4.438-7.426,3.89-9.186-.579-1.864-6.467-4.559-12.7-7.411-.165-.058-.324-.12-.48-.19-1.206-.544-2.472-1.01-3.811-1.504-1.715-.633-3.487-1.287-5.256-2.151-1.875-.917-2.652-3.18-1.735-5.056.916-1.875,3.18-2.651,5.056-1.735,1.424.696,2.943,1.257,4.552,1.851,1.402.517,2.852,1.052,4.297,1.702.187.067.358.135.526.212,9.471,4.334,15.188,6.951,16.771,12.038,1.433,4.609-1.244,9.659-4.62,15.308-7.618,12.749-18.598,21.706-32.633,26.624-17.827,6.245-33.311,2.878-44.615.419-4.608-1.003-8.587-1.868-11.902-1.974-.072-.002-.143-.006-.215-.013-.311-.03-.801.035-1.317.104-.425.056-.849.109-1.272.141-.593.044-1.31.199-2.068.364-.794.172-1.694.368-2.637.473-3.843.426-7.778,1.104-11.584,1.761-6.666,1.149-13.52,2.33-20.586,2.331Z" fill="#151518"/>
                            <path d="M124.444,182.457c-.054,0-.108,0-.163-.002-.967-.023-1.884-.313-2.771-.594-.349-.109-.707-.229-1.028-.3-24.892-5.541-42.983-18.029-56.934-39.303-.097-.147-.186-.297-.272-.448l-.237-.377c-1.502-2.333-3.373-5.237-4.562-7.698-.493-1.02-.806-1.987-1.083-2.842-.146-.451-.29-.904-.469-1.358-4.967-12.607-7.351-31.785-1.478-46.208,3.57-8.763,5.355-9.87,14.698-9.094,12.342,1.025,24.53,7.577,32.777,12.56,5-7.455,16.94-23.055,24.333-23.055.032,0,.063,0,.095,0,7.627.14,22.928,19.098,26.548,26.496,1.546,3.157,3.25,8.672,3.936,11.147,1.799,6.494,2.778,13.3,2.757,19.164-.076,21.546-9.146,34.856-16.435,45.55-2.542,3.729-4.943,7.252-6.717,10.736-.948,1.859-3.224,2.597-5.083,1.652-1.86-.947-2.6-3.224-1.652-5.084,1.993-3.912,4.525-7.628,7.206-11.562,7.057-10.354,15.054-22.09,15.123-41.32.019-5.117-.887-11.357-2.483-17.119-.87-3.142-2.371-7.659-3.439-9.842-3.991-8.154-16.468-21.061-19.922-22.229-4.041,1.127-16.774,16.476-19.691,22.364-18.041,36.41,7.515,66.787,17.136,78.222,2.047,2.434,2.974,3.553,3.47,4.581.744,1.539.72,2.942-.072,4.171-.765,1.188-1.972,1.789-3.587,1.789ZM65.601,81.525c-.479,0-.842.028-1.021.1-.434.387-1.455,2.895-2.13,4.554-5.058,12.417-2.762,29.744,1.51,40.586.237.604.434,1.203.627,1.802.23.712.429,1.327.695,1.877.989,2.047,2.721,4.736,4.113,6.897l.19.295c.086.134.164.267.241.4l.055.094c11.467,17.486,25.997,28.507,45.344,34.266-11.152-13.899-31.392-43.819-15.944-78.971-7.594-4.606-18.863-10.753-29.759-11.658-1.394-.116-2.913-.242-3.92-.242Z" fill="#151518"/>
                            <path d="M126.203,185.619c-1.633,0-3.139-1.066-3.624-2.712-.59-2.002.555-4.104,2.557-4.693l1.809-.564c.384-.126.768-.252,1.151-.385l.542-.186c.744-.255,1.488-.51,2.229-.777,23.218-8.413,38.395-15.148,53.334-37.698.11-.197.197-.345.291-.487l.746-1.143c1.635-2.496,3.179-4.854,4.344-7.352,5.505-11.779,7.664-32.38,2.106-44.276-.405-.868-1.64-3.508-2.195-3.923-.412-.119-2.312.111-3.988.314-.571.069-1.188.145-1.857.221-10.089,1.147-19.693,6.251-28.569,11.595-1.788,1.077-4.11.499-5.188-1.288-1.076-1.789-.499-4.111,1.289-5.188,9.607-5.785,20.088-11.319,31.612-12.63.649-.074,1.248-.146,1.803-.214,7.736-.937,10.113-.282,13.941,7.913,6.753,14.452,4.232,37.115-2.105,50.677-1.394,2.984-3.16,5.683-4.868,8.292l-.739,1.132c-.128.224-.222.383-.324.536-16.182,24.427-33.099,31.945-57.058,40.627-.783.283-1.569.553-2.354.821l-.53.182c-.414.143-.833.281-1.251.419l-2.032.633c-.356.105-.716.155-1.07.155Z" fill="#151518"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Logo;