import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const FooterLinks = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 10,
        right: 10,
        display: 'flex',
        gap: 2,
        zIndex: 1000, // Ensure footer is above other elements
        '@media (max-width: 600px)': {
          position: 'static',
          justifyContent: 'center',
          gap: 1,
          backgroundColor: 'background.default', // Optional: add background to prevent overlap
          padding: 1,
          width: '100%', // Make footer take full width on mobile
        },
      }}
    >
      <Typography variant="caption">
        <Link href="/terms-and-conditions" color="text.secondary" underline="hover">
          Terms & Conditions
        </Link>
      </Typography>
      <Typography variant="caption">
        <Link href="/privacy-policy" color="text.secondary" underline="hover">
          Privacy Policy
        </Link>
      </Typography>
    </Box>
  );
};

export default FooterLinks;
