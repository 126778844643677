import React, { useState, useEffect, useRef } from "react";
import { auth } from "../firebase-config";
import BotImage from "./BotImage.svg";
import UserImage from "./UserImage.svg";
import styles from "./LandingPage.module.css";
import {
    Box,
    TextField,
    Typography,
    Avatar,
    IconButton,
    Paper,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
// REMOVE AXIOS IMPORT - WE WILL USE FIRESTORE SDK NOW
// import axios from "axios"; 
import TypingIndicator from "./TypingIndicator";
import { useSearchParams } from "react-router-dom";
// IMPORT FIRESTORE FUNCTIONS
import {
    collection,
    doc,
    setDoc,
    addDoc,
    serverTimestamp,
    getDoc,
    arrayUnion,
    query,
    orderBy,
} from "firebase/firestore";
import { db } from "../firebase-config";
import UserAvatar from "./UserAvatar";
import { alpha, useTheme } from '@mui/material/styles';

export const ChatPage = ({ user, isDarkMode }) => {
    console.log('User in ChatPage:', user);
    const searchParams = useSearchParams();
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [firstMsgFromUser] = useState([
        {
            content: searchParams[0].get("first"),
            role: "user",
        },
    ]);
    const [messages, setMessages] = useState(firstMsgFromUser);
    const [input, setInput] = useState("");

    const [text, setDefaultState] = useState("");

    const [botTyping, setBotTyping] = useState(false);
    const [location, setLocation] = useState({ latitude: null, longitude: null });

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const messageEndRef = useRef(null);

    const handleInputChange = (event) => {
        setBtnDisabled(!event.target.value);
        setInput(event.target.value);
        setDefaultState(event.target.value);
    };

    const handleMessageSubmit = async (e) => {
        e.preventDefault();

        if (input.trim() === "") return; // Don't send empty messages

        const userMessage = { role: "user", content: input };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput("");
        setDefaultState("");
        setBtnDisabled(true);
        setBotTyping(true);

        try {
            // 1. STORE USER MESSAGE IN FIRESTORE
            console.log("Storing user message in Firestore");
            await sendMessageToFirestore(input, "user");

            // 2. GET BOT RESPONSE (You can keep calling your Cloud Function)
            console.log("Getting bot response");
            const chatbotResponse = await sendMessageToBackend(input);
            setBotTyping(false);
            if (chatbotResponse) {
                const botMessage = { role: "system", content: chatbotResponse };
                setMessages((prevMessages) => [...prevMessages, botMessage]);

                console.log("Storing bot message in Firestore");
                await sendMessageToFirestore(chatbotResponse, "system");
            } else {
                throw new Error("Received null response from backend");
            }
        } catch (error) {
            console.error("Error handling message:", error);
            // Handle errors gracefully, e.g., display an error message to the user
            setBotTyping(false);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "system",
                    content: "Sorry, there was an error processing your request. Please try again.",
                },
            ]);
        }
    };

    // MODIFY sendMessageToFirestore FUNCTION
    const sendMessageToFirestore = async (message, role) => {
        if (!auth.currentUser) {
            console.error("No authenticated user found");
            return;
        }

        const userId = auth.currentUser.uid;
        const sessionDate = new Date().toISOString().split("T")[0];
        const sessionRef = doc(db, "Users", userId, "sessions", sessionDate);
        
        try {
            const newMessage = {
                text: message,
                from: role === "user" ? "user" : "system",
                timestamp: new Date().toISOString() // Use a string timestamp instead
            };
            await setDoc(sessionRef, {
                date: serverTimestamp(),
                messages: arrayUnion(newMessage)
            }, { merge: true });
    
            console.log("Message stored to Firestore successfully!");
        } catch (error) {
            console.error("Error storing message to Firestore:", error);
            // Handle the error, e.g., by showing an error message to the user
            throw error; // Re-throw to be caught in handleMessageSubmit
        }
    };

    const sendMessageToBackend = async (message) => {
        if (!auth.currentUser) {
            console.error("No authenticated user found");
            throw new Error("No authenticated user found");
        }
    
        const userId = auth.currentUser.uid;
        const isEmotionButtonMessage = localStorage.getItem('isEmotionButtonMessage') === 'true';
        console.log("isEmotionButtonMessage before sending to backend:", isEmotionButtonMessage);
    
        try {
            console.log("Sending message to backend:", message);
            console.log("Sending request with location:", location.latitude, location.longitude);
            const response = await fetch('/api/chatbot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
                },
                body: JSON.stringify({
                    message,
                    latitude: location.latitude,
                    longitude: location.longitude,
                    conversationHistory: messages,
                    isEmotionButtonMessage,
                }),
            });
    
            // Clear the flag after sending the message
            localStorage.removeItem('isEmotionButtonMessage');
    
            console.log("Response status:", response.status);
    
            if (!response.ok) {
                const errorText = await response.text();
                console.error("Backend error:", errorText);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log("Full backend response:", data);
            if (!data.message) {
                console.error("Backend response is missing message property:", data);
                throw new Error("Invalid response from backend");
            }
            return data.message;
        } catch (error) {
            console.error("Error while sending message to backend:", error);
            throw error;
        }
    };

    const handleFirstMsg = async () => {
        // Only proceed if there's no existing messages
        if (messages.length > 1 || messages[0].processed) return;
        setBotTyping(true);
        try {
            const firstMsg = { ...messages[0], processed: true };
            setMessages([firstMsg]);
            // Store the first user message in Firestore
            console.log("Handling first message:", firstMsgFromUser[0].content);
            await sendMessageToFirestore(firstMsgFromUser[0].content, "user");

            console.log("Sending first message to backend");
            const chatbotResponse = await sendMessageToBackend(firstMsg.content);
            console.log("Received response from backend:", chatbotResponse);
            setBotTyping(false);
            const botMessage = { role: "system", content: chatbotResponse };
            setMessages((prevMessages) => [...prevMessages, botMessage]);

            // Store bot message in Firestore
            console.log("Storing bot response in Firestore");
            await sendMessageToFirestore(chatbotResponse, "system");
        } catch (error) {
            // Handle potential errors here
            console.error(
                "Error processing first message:",
                error
            );
            setBotTyping(false);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    role: "system",
                    content:
                        "Sorry, there was an error. Please try again.",
                },
            ]);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleMessageSubmit(event);
        }
    };

    useEffect(() => {
        const userId = auth.currentUser?.uid;
        if (userId) {
            fetchMessages();
        }
    }, [auth.currentUser]);
    
        const fetchMessages = async () => {
            try {
                const sessionDate = new Date().toISOString().split("T")[0];
                const userId = auth.currentUser.uid;
                const sessionRef = doc(db, "Users", userId, "sessions", sessionDate);
                const sessionSnap = await getDoc(sessionRef);

                if (sessionSnap.exists()) {
                    const sessionData = sessionSnap.data();
                    if (sessionData.messages && sessionData.messages.length > 0) {
                        const fetchedMessages = sessionData.messages.map(msg => ({
                            role: msg.from === "user" ? "user" : "system",
                            content: msg.text,
                            timestamp: msg.timestamp,
                            processed: true
                        }));
                        setMessages(fetchedMessages);
                    } else {
                        // Keep the firstMsgFromUser if no messages in Firestore
                        setMessages([{ ...firstMsgFromUser[0], processed: false }]);
                      }
                    } else {
                      // Keep the firstMsgFromUser if no session in Firestore
                      setMessages([{ ...firstMsgFromUser[0], processed: false }]);
                    }
                    console.log("Fetched messages:", messages);
                  } catch (error) {
                    console.error("Error fetching messages:", error);
                    setMessages(firstMsgFromUser);
                  }
                };

    useEffect(() => {
        console.log("Current messages:", messages);
        if (messages.length === 1 && messages[0].role === "user" && !messages[0].processed) {
            console.log("Calling handleFirstMsg");
            handleFirstMsg();
        }
        getUserLocation();
}, [messages]); 

    useEffect(() => {
        messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        const isEmotionButtonMessage = localStorage.getItem('isEmotionButtonMessage') === 'true';
        if (isEmotionButtonMessage) {
            console.log("Emotion button message detected on ChatPage mount");
            // You can set a state here if needed
        }
    }, []);

    return (
        <div className={`${styles.chatpage} chat-page-container`}>
            <Box sx={{ 
                flexGrow: 1, 
                overflow: "auto", 
                p: 5,
                mb: 2, // Add margin at the bottom
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    borderRadius: '3px',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    },
                },
            }}>
                {messages.map((message, index) => (
                    <Message key={index} message={message} isDarkMode={isDarkMode} />
                ))}
                {botTyping && <TypingIndicator />}
                <div ref={messageEndRef} />
            </Box>

            <div className={styles.chatbottom}>
                <Box className={styles.messagetextareaframe} sx={{
                    backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(245, 209, 199, 0.35)',
                }}>
                    <TextField
                        className={styles.typeYourMessage}
                        id="text-field"
                        placeholder="Type your message..."
                        color="primary"
                        variant="standard"
                        multiline
                        InputProps={{ disableUnderline: true }}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        value={text}
                    />
                    <IconButton
                        color="primary"
                        disabled={btnDisabled}
                        onClick={handleMessageSubmit}
                        style={{ color: btnDisabled ? "#3b3b3b" : "inherit" }}
                    >
                        <SendIcon />
                    </IconButton>
                </Box>
            </div>
        </div>
    );
};

const Message = ({ message, isDarkMode }) => {
  console.log("Rendering message:", message.content);
  const theme = useTheme();
  const isBot = message.role === "system";

  const renderContent = (content) => {
    if (!content) return null;
    const parts = content.split(/(\[.*?\]\(.*?\))/g);
    return parts.map((part, index) => {
      const match = part.match(/\[(.*?)\]\((.*?)\)/);
      if (match) {
        return (
          <a key={index} href={match[2]} target="_blank" rel="noopener noreferrer">
            {match[1]}
          </a>
        );
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        alignItems: 'flex-start', // Add this line
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: isBot ? "row" : "row-reverse",
          alignItems: 'flex-start', // Add this line
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: '50px', // Add this line
          }}
        >
          {isBot ? (
            <Avatar
              sx={{
                bgcolor: "primary.main",
              }}
              alt="BotImage"
              src={BotImage}
            />
          ) : (
            <UserAvatar />
          )}
          <Typography variant="body2">
            {isBot ? "Buddy" : "You"}
          </Typography>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            ml: isBot ? 1 : 0,
            mr: isBot ? 0 : 1,
            backgroundColor: isBot
              ? (isDarkMode ? "primary.dark" : alpha(theme.palette.primary.light, 0.5))
              : (isDarkMode ? "secondary.dark" : "secondary.light"),
            borderRadius: isBot
              ? "20px 20px 20px 5px"
              : "20px 20px 5px 20px",
            maxWidth: '80%', // Add this line
            wordBreak: 'break-word', // Add this line
          }}
        >
          <Typography variant="body1" sx={{ overflowWrap: "anywhere", whiteSpace: "pre-wrap" }}>
            {renderContent(message.content)}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default ChatPage;