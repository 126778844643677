import React, { useEffect, useState } from "react";
import "./App.css";
import Onboarding from "./components/Onboarding";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase-config"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box'; 
import AppTitle from "./components/AppTitle"; 
import LandingPage from "./components/LandingPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green } from "@mui/material/colors";
import { Route, Routes } from 'react-router-dom';
import { ChatPage } from "./components/ChatPage";
import TermsAndConditionsPage from './components/TermsAndConditionsPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import FooterLinks from './components/FooterLinks';
import ThemeToggle from './components/ThemeToggle'; // Import ThemeToggle

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#66A5AD',
    },
    secondary: {
      main: '#9A8FBA',
    },
    background: {
      default: "#121F2A",
      paper: "#1A2936",
    },
    text: {
      primary: '#E0E0E0',
      secondary: '#B0B0B0'
    },
    accent: {
      main: '#FFD485',
    }
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  h6: {
    fontFamily: 'Quicksand'
  }
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#4A8C94',
    },
    secondary: {
      main: '#F5D0C5',
    },
    accent: {
      main: '#FFBF69',
    },
    background: {
      default: "#FFF5E6",
      paper: "#FFFFFF",
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#7D7D7D'
    },
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  h6: {
    fontFamily: 'Quicksand'
  }
});

function App() {
  const [user, setUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
    });
    
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('isDarkMode', JSON.stringify(newTheme));
  };

  useEffect(() => {
    const rootStyle = document.documentElement.style;
    if (isDarkMode) {
      rootStyle.setProperty('--text', '#E0E0E0');
      rootStyle.setProperty('--background', '#121F2A');
      rootStyle.setProperty('--primary', '#66A5AD');
      rootStyle.setProperty('--secondary', '#9A8FBA');
      rootStyle.setProperty('--accent', '#FFD485');
    } else {
      rootStyle.setProperty('--text', '#4A4A4A');
      rootStyle.setProperty('--background', '#FFF5E6');
      rootStyle.setProperty('--primary', '#4A8C94');
      rootStyle.setProperty('--secondary', '#F5D0C5');
      rootStyle.setProperty('--accent', '#FFBF69');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('isDarkMode');
    if (savedTheme !== null) {
      setIsDarkMode(JSON.parse(savedTheme));
    }
  }, []);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'background.default' }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'flex-start', 
          padding: 2, 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          zIndex: 1000, 
          bgcolor: 'background.default',
          '.chat-page-container + &': {
            zIndex: 999
          }
        }}>
          <AppTitle />
          <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
        </Box>
        <Container disableGutters sx={{ flexGrow: 1, mt: 0 }}>
          <Routes>
            <Route path='/' element={<LandingPage user={user} isDarkMode={isDarkMode} />} />
            <Route path='chat-page' element={<ChatPage user={user} isDarkMode={isDarkMode} />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
          </Routes>
          <FooterLinks />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;